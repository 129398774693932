import {
  addBusinessDays,
  isDate,
  isDateOnWeekend,
  parseStringToDate,
} from "app/shared/dates.ts";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router";
import { getDateDifferenceInDays } from "server/api/utils/date-utils";
import { useOptionalUser } from "./useUser";

export const useDateRanges = ({
  defaultEndDate = null,
  defaultStartDate = null,
  defaultAdditionalDate = null,
  minDate = null,
}: {
  defaultStartDate?: string | null;
  defaultEndDate?: string | null;
  defaultAdditionalDate?: string | null;
  minDate?: string | null;
}) => {
  const user = useOptionalUser();
  const isAdmin = Boolean(user?.authOptions.isAdmin);
  const [searchParams] = useSearchParams();

  const startDateParam = searchParams.get("startDate");
  const endDateParam = searchParams.get("endDate");

  const minimumDate = React.useMemo(() => {
    const currentDate = new Date();
    const defaultMinDate = addBusinessDays({
      date: currentDate,
      daysToAdd: isAdmin
        ? 1
        : currentDate.getHours() < 17 || isDateOnWeekend(currentDate)
          ? 2
          : 3,
    });
    if (minDate) {
      const date = parseStringToDate(minDate);
      if (isDate(date)) {
        return date;
      }
    }
    return defaultMinDate;
  }, [minDate]);

  const [selectedDates, setSelectedDates] = React.useState<{
    startDate: string;
    endDate: string;
    additionalDate?: string;
  }>(() => {
    return {
      startDate: defaultStartDate ?? startDateParam ?? "",
      endDate: defaultEndDate ?? endDateParam ?? "",
      additionalDate: defaultAdditionalDate ?? "",
    };
  });

  const isOutated = React.useMemo(() => {
    if (!selectedDates.startDate) return false;
    const startDate = parseStringToDate(selectedDates.startDate);

    return startDate < minimumDate;
  }, [selectedDates?.startDate, minimumDate]);

  const dateDifferenceInDays = useMemo(() => {
    if (!selectedDates.startDate || !selectedDates.endDate) return 0;
    return getDateDifferenceInDays({
      endDate: parseStringToDate(selectedDates.endDate),
      startDate: parseStringToDate(selectedDates.startDate),
    });
  }, [selectedDates.endDate, selectedDates.startDate]);

  return {
    selectedDates,
    setSelectedDates,
    minimumDate,
    isOutated,
    dateDifferenceInDays,
  };
};
