import { fr } from "date-fns/locale";
import { NavArrowLeft, NavArrowRight } from "iconoir-react";
import type * as React from "react";
import { DayPicker, getDefaultClassNames } from "react-day-picker";
import { cn } from "utils/utils.ts";
type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const defaultClassNames = getDefaultClassNames();

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn(
        "m-0 p-1",
        "![--rdp-accent-color:#14B480]", // Main teal color
        "![--rdp-accent-background-color:#E2F2F1]", // light-turquoise for background
        "![--rdp-today-color:#14B480]", // teal for today
        "![--rdp-range_middle-background-color:#E2F2F1]", // light-turquoise for range background
        "![--rdp-range_start-date-background-color:#14B480]", // teal for range start
        "![--rdp-range_end-date-background-color:#14B480]", // teal for range end
        className,
      )}
      locale={fr}
      classNames={{
        caption_label: "text-base pl-4 pt-3",
        // button_previous: cn(
        //   buttonVariants({ variant: "outline" }),
        //   "h-7 w-7 p-0"
        // ),
        // button_next: cn(
        //   buttonVariants({ variant: "outline" }),
        //   "h-7 w-7 p-0"
        // ),
        day_button: `${defaultClassNames.day_button} !text-[0.8rem]`,
        head_cell: "text-[0.8rem]",
        day: cn("size-6 text-[0.8rem] p-0 rounded-md"),
        day_selected: cn(
          `${defaultClassNames.selected} !text-[0.8rem] !bg-teal !text-white !hover:bg-teal !hover:text-white`,
        ),
        day_today: cn(
          `${defaultClassNames.today} !text-[0.8rem] !bg-teal !text-white`,
        ),
        day_outside: cn(`${defaultClassNames.outside} opacity-50`),
        day_disabled: cn(`${defaultClassNames.disabled} opacity-50`),
        chevron: "size-4 fill-teal",
        ...classNames,
      }}
      components={{
        // @ts-ignore
        IconLeft: () => <NavArrowLeft className="size-4" />,
        // @ts-ignore
        IconRight: () => <NavArrowRight className="size-4" />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
