import {
  addDays,
  differenceInDays,
  format,
  isValid,
  parse,
  set,
} from "date-fns";

export const isDateOnWeekend = (date: Date) => {
  if (!date) return true;
  return date.getDay() === SUNDAY || date.getDay() === SATURDAY;
};

import { fr } from "date-fns/locale";
//* Par défaut, une location dure 7 jours si le client n'a pas spécifié de date de début et de fin.
export const DEFAULT_RENT_DAYS = 7;
export const DEFAULT_START_DAYS_FROM_NOW = 2;
export const SATURDAY = 6;
export const SUNDAY = 0;
export const DAYS_IN_A_MONTH = 30;

export const formatDayDate = ({ date }: { date: Date }) => {
  return date.toLocaleDateString("fr-FR");
};

export const formatHourDate = ({ date }: { date: Date }) => {
  // returns '12h00'

  return date
    .toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(":", "h");
};

export const formatMarketplaceDate = ({ date }: { date: Date | null }) => {
  if (!date) return "";
  return format(date, "dd/MM/yyyy", {
    locale: fr,
  });
};

/**
 * New date may take milliseconds, therefore just multiply by 1000
 *  source: https://stackoverflow.com/questions/4631928/convert-utc-epoch-to-local-date#comment78302821_22237139
 */
export const convertEpochToDate = (epoch: number) => {
  return new Date(epoch * 1000);
};

// * Cette méthode permet de vérifier si les dates sont valables :
// * - La date de début est supérieure à la date du jour.
// * - La date de fin est supérieure à la date du jour et du début
export const verifyBookingDateAvailability = ({
  endDate,
  startDate,
  options = {
    allowOutdated: false,
    pickNextAvailableDateIfOutdated: false,
  },
  isAdmin,
}: {
  startDate: Date | null;
  endDate: Date | null;
  options?: DateOptions;
  isAdmin: boolean;
}) => {
  let haveOutdatedDatesBeenUpdated = false;
  const minAvailableDate = addBusinessDays({
    // Création d'une date aujourd'hui à minuit
    date: set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    }),
    // Ajout de 2 jours sauf si + de 17h alors 3 pour respecter la logique de jours ouvrés

    daysToAdd: isAdmin
      ? 1
      : new Date().getHours() < 17 || isDateOnWeekend(new Date())
        ? 2
        : 3,
  });

  if (!startDate) {
    startDate = minAvailableDate;
  }

  // if (!endDate) {
  //   endDate = addDays(startDate, DEFAULT_RENT_DAYS);
  // }

  if (startDate < minAvailableDate && options.pickNextAvailableDateIfOutdated) {
    startDate = minAvailableDate;
    endDate = addDays(startDate, DEFAULT_RENT_DAYS);

    haveOutdatedDatesBeenUpdated = true;
  }

  // Si la date de début est inférieure à la date du jour à minuit + 2 jours, nous lançons une early return
  if (startDate < minAvailableDate && !options.allowOutdated) {
    console.error(
      `Vous ne pouvez commander que 2 jours ouvrés à l'avance. (Date minimum : ${formatMarketplaceDate(
        { date: minAvailableDate },
      )} - Date sélectionnée : ${formatMarketplaceDate({
        date: startDate,
      })})`,
    );

    throw new Error(
      `Vous ne pouvez commander que 2 jours ouvrés à l'avance. (Date minimum : ${formatMarketplaceDate(
        { date: minAvailableDate },
      )} - Date sélectionnée : ${formatMarketplaceDate({
        date: startDate,
      })})`,
    );
  }

  // Si la date de début commence un week-end (samedi ou dimanche), nous lançons une early return
  if (isDateOnWeekend(startDate) && options.disallowWeekends) {
    console.error(
      `Vous ne pouvez pas commencer une location le week-end. (Date sélectionnée : ${formatMarketplaceDate(
        { date: startDate },
      )})`,
    );
    throw new Error(
      `Vous ne pouvez pas commencer une location le week-end. (Date sélectionnée : ${formatMarketplaceDate(
        {
          date: endDate,
        },
      )})`,
    );
  }

  verifyBookingEndDateAvailability({ endDate, startDate, options });
  return { haveOutdatedDatesBeenUpdated, endDate, startDate };
};

export const verifyBookingEndDateAvailability = ({
  endDate,
  startDate,
  options,
}: {
  startDate: Date;
  endDate: Date;
  options: DateOptions;
}) => {
  if (isDateOnWeekend(endDate) && options.disallowWeekends) {
    console.error(
      `Vous ne pouvez pas effectuer une clôture le week-end. (Date sélectionnée : ${formatMarketplaceDate(
        { date: endDate },
      )})`,
    );
    throw new Error(
      `Vous ne pouvez pas effectuer une clôture le week-end. (Date sélectionnée : ${formatMarketplaceDate(
        {
          date: endDate,
        },
      )})`,
    );
  }

  // Si la date de fin est inférieure à la date du début, nous lançons une early return
  if (endDate && endDate < startDate) {
    console.error(
      `La date de relève doit être supérieure à la date de dépose. (Date sélectionnée : ${formatMarketplaceDate(
        { date: endDate },
      )} - Date de dépose : ${formatMarketplaceDate({
        date: startDate,
      })})`,
    );
    throw new Error(
      `La date de relève doit être supérieure à la date de dépose. (Date sélectionnée : ${formatMarketplaceDate(
        {
          date: endDate,
        },
      )} - Date de dépose : ${formatMarketplaceDate({
        date: startDate,
      })})`,
    );
  }
};

/**
 * Check if stringified dates are valid dates,
 * Then checks if a booking can be placed at these dates
 * Then returns them as date.
 *
 * @param
 * @returns
 */
export const getBookingDates = ({
  startDate,
  endDate,
  options,
  isAdmin,
}: {
  startDate: string;
  endDate: string;
  options: DateOptions;
  isAdmin: boolean;
}) => {
  const isValidStartDate = isStringValidDate(startDate);

  let bookingStartDate = isValidStartDate
    ? parseStringToDate(startDate)
    : // Si l'utilisateur n'a pas sélectionné de date et qu'on se retrouve un week-end, on sélectionne le lundi
      addBusinessDays({
        date: new Date(),
        daysToAdd:
          new Date().getHours() < 17 || isDateOnWeekend(new Date())
            ? DEFAULT_START_DAYS_FROM_NOW
            : DEFAULT_START_DAYS_FROM_NOW + 1,
      });
  // Si l'utilisateur n'a pas sélectionné de date et qu'on se retrouve un week-end, on sélectionne le lundi suivant.

  if (options.disallowWeekends && isDateOnWeekend(bookingStartDate)) {
    bookingStartDate = addBusinessDays({
      date: bookingStartDate,
      daysToAdd: 1,
    });
  }

  const isValidEndDate = isStringValidDate(endDate);

  let bookingEndDate = null;
  if (endDate) {
    bookingEndDate = isValidEndDate
      ? parseStringToDate(endDate)
      : // SI la date de fin n'est pas spécifiée (comme pour les bookings récurrents), on la définie automatiquement à J+7
        addDays(bookingStartDate, DEFAULT_RENT_DAYS);
  }
  if (options.disallowWeekends && isDateOnWeekend(bookingEndDate)) {
    bookingEndDate = addBusinessDays({
      date: bookingEndDate,
      daysToAdd: 1,
    });
  }

  const {
    haveOutdatedDatesBeenUpdated,
    endDate: updatedEndDate,
    startDate: updatedStartDate,
  } = verifyBookingDateAvailability({
    startDate: bookingStartDate,
    endDate: bookingEndDate,
    options,
    isAdmin,
  });
  bookingEndDate = updatedEndDate;
  bookingStartDate = updatedStartDate;

  const dateDifferenceInDays = bookingEndDate
    ? getDateDifferenceInDays({
        endDate: bookingEndDate,
        startDate: bookingStartDate,
      })
    : DEFAULT_RENT_DAYS;

  bookingStartDate = set(bookingStartDate, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  if (bookingEndDate) {
    bookingEndDate = set(bookingEndDate, {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }

  return {
    bookingStartDate,
    bookingEndDate,
    dateDifferenceInDays,
    haveOutdatedDatesBeenUpdated,
  };
};

export const isStringValidDate = (date: string) => {
  if (!date) return false;
  return isValid(parseStringToDate(date));
};

export const parseStringToDate = (date: string) => {
  if (!date) return null;
  return set(
    parse(date, "dd/MM/yyyy", new Date(), {
      locale: fr,
    }),
    {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    },
  );
};

export const getDateDifferenceInDays = ({
  endDate,
  startDate,
}: {
  endDate: Date;
  startDate: Date;
}) => {
  // Always add more day, to include the current day
  const dateDifferenceInDays = differenceInDays(endDate, startDate) + 1;

  return dateDifferenceInDays;
};

// Helper function to add business days
export const addBusinessDays = ({
  date,
  daysToAdd,
}: {
  date: Date;
  daysToAdd: number;
}) => {
  let count = 0;

  let newDate = new Date(date);

  while (count < daysToAdd) {
    newDate = new Date(date.setDate(date.getDate() + 1));
    // If the new date is a Saturday or Sunday, continue to the next day
    if (newDate.getDay() !== 0 && newDate.getDay() !== 6) {
      count++;
    }
    // if (newDate.getDay() === 0 || newDate.getDay() === 6) {
    //   if (newDate.getDay() === 6) count++;
    //   count++;
    // }
  }
  return newDate;
};

export const countBusinessDays = ({
  dateOne = new Date(),
  dateTwo,
}: {
  dateOne?: Date;
  dateTwo: Date;
}) => {
  let count = 0;

  const startDate = dateOne > dateTwo ? dateTwo : dateOne;
  const endDate = dateOne > dateTwo ? dateOne : dateTwo;

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  // Loop over each day between the start date and the end date
  while (startDate < endDate) {
    const isDateOnBusinessDay = !isDateOnWeekend(startDate);
    // If the current day is a weekday, increment the count
    if (isDateOnBusinessDay) {
      count++;
    }
    // Move to the next day
    startDate.setDate(startDate.getDate() + 1);
  }

  return count;
};

// This function may be confusing. We are counting all hours between two dates, unless they are on a weekend.
export const countBusinessHours = ({
  dateOne,
  dateTwo,
}: {
  dateOne: Date;
  dateTwo: Date;
}) => {
  let totalHours = 0;
  const startDate = dateOne > dateTwo ? dateTwo : dateOne;
  const endDate = dateOne > dateTwo ? dateOne : dateTwo;

  while (endDate.getTime() - startDate.getTime() >= 3600 * 1000) {
    // Check if at least one hour difference remains
    if (!isDateOnWeekend(startDate)) {
      totalHours++;
    }
    // Move to the next hour
    startDate.setHours(startDate.getHours() + 1);
  }

  return totalHours;
};

export type DateOptions = {
  allowOutdated: boolean;
  disallowWeekends?: boolean;
  pickNextAvailableDateIfOutdated?: boolean;
};
