import { formatDate, isValid, parseStringToDate } from "app/shared/dates.ts";
import type { SearchBarTheme } from "components/Bookings/MarketplaceSearchBar.tsx";
import { Label } from "components/UI/Label";
import { paragraphVariants } from "components/UI/Paragraph";
import { Button } from "components/shadcn-ui/button.tsx";
import { Calendar } from "components/shadcn-ui/calendar.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/shadcn-ui/popover.tsx";
import { fr } from "date-fns/locale";
import { useDateRanges } from "hooks/useDateRanges";
import { Calendar as CalendarIcon } from "iconoir-react";
import React, { useEffect, useId } from "react";
import type { DateRange } from "react-day-picker";
import { getDateDifferenceInDays } from "server/api/utils/date-utils";
import { cn } from "utils/utils.ts";
import { ErrorList, type ListOfErrors } from "../Forms/forms";

// * Ce composant est affiché sur l'interface de location et permet de choisir la date de début et de fin de la prestation
export const DatepickerInput = ({
  selectedStartDate,
  selectedEndDate,
  onDateChange,
  // theme = "light",
  variant = "popover",
  showRecurring = false,
  disableMinDate = false,
  minDate = null,
  numberOfMonths = 1,
  placeholder = "Choisir une date",
  errors,
  inputProps,
  theme,
  displayRangeLabel = false,
  labelProps = {
    children: "Choisissez une date de prestation",
  },
}: {
  variant?: "inline" | "popover";
  selectedStartDate: string | undefined;
  selectedEndDate: string | undefined;
  theme?: SearchBarTheme;
  placeholder?: string;
  displayRangeLabel?: boolean;
  showRecurring?: boolean;
  required?: boolean;
  disableMinDate?: boolean;
  minDate?: string | null;
  numberOfMonths?: number;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onDateChange: ({
    endDate,
    startDate,
  }: {
    startDate: string;
    endDate: string;
  }) => void;
  errors?: ListOfErrors;
}) => {
  // This is to ensure that picking range dates works becauase as of 9.0 it bugs
  // const [lastStartDate, setLastStartDate] = React.useState<Date | undefined>();

  const { minimumDate } = useDateRanges({
    defaultEndDate: selectedEndDate,
    defaultStartDate: selectedStartDate,
    minDate: minDate,
  });

  const startDate = React.useMemo(() => {
    const parsedStartDate = selectedStartDate
      ? parseStringToDate(selectedStartDate)
      : undefined;

    const isValidStartDate = isValid(parsedStartDate);

    return isValidStartDate ? parsedStartDate : undefined;
  }, [selectedStartDate]);

  const endDate = React.useMemo<Date | undefined>(() => {
    const parsedEndDate = selectedEndDate
      ? parseStringToDate(selectedEndDate)
      : undefined;

    const isValidEndDate = parsedEndDate && isValid(parsedEndDate);
    return isValidEndDate ? parsedEndDate : undefined;
  }, [selectedEndDate]);

  const onRangeDateChange = (dates: DateRange | undefined) => {
    const { from, to } = dates || {};
   
    const formattedStartDate = from
      ? formatDate({ date: from, dateFmt: "dd/MM/yyyy" })
      : "";
    const formattedEndDate = to
      ? formatDate({ date: to, dateFmt: "dd/MM/yyyy" })
      : "";

    onDateChange({
      endDate: formattedEndDate,
      startDate: formattedStartDate,
    });
  };

  const onStartDateChange = (startDate: Date | undefined) => {
    const formattedStartDate = startDate
      ? formatDate({ date: startDate, dateFmt: "dd/MM/yyyy" })
      : "";

    onDateChange({ endDate: "", startDate: formattedStartDate });
  };

  React.useEffect(() => {
    if (!showRecurring) return;
    if (!selectedEndDate) return;
    if (!selectedStartDate) return;
    // If we switch from date range to date, we set the end date to zero (startDate will always be picked date)
    onDateChange({
      endDate: "",
      startDate: selectedStartDate,
    });
  }, [showRecurring, selectedStartDate, onDateChange, selectedEndDate]);
  const [openDropdown, setOpenDropDown] = React.useState(false);

  React.useEffect(() => {
    if (!openDropdown) return;
    if (!showRecurring && startDate && endDate) {
      setOpenDropDown(false);
    } else if (showRecurring && startDate) {
      setOpenDropDown(false);
    }
  }, [startDate, endDate]);

  const today = new Date();
  // const { children, ...otherInputProps } = inputProps || {}
  const fallbackId = useId();
  const id = inputProps?.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const dateWrapper = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!errors || !errors.length || !buttonRef.current || !dateWrapper.current)
      return;
    buttonRef.current.click();
    dateWrapper.current?.scrollIntoView({ behavior: "smooth" });
  }, [errors]);

  const CalendarComponent = () => {
    return (
      <>
     
        {showRecurring ? (
          <Calendar
            mode={"single"}
            selected={startDate || today}
            onSelect={onStartDateChange}
            locale={fr}
            defaultMonth={startDate || minimumDate}
            numberOfMonths={numberOfMonths}
            disabled={[
              {
                dayOfWeek: [0, 6],
              },
              ...(disableMinDate ? [] : [{ before: minimumDate }]),
            ]}
          />
        ) : (
          <Calendar
            mode={"range"}
            selected={{ from: startDate, to: endDate }}
            onSelect={(dataRange) => {
              // First date selection is the start date
              if (!startDate && !endDate && Boolean(dataRange)) {
                dataRange.to = undefined;
              } 
              onRangeDateChange(dataRange)}
            }
            // initialFocus
            locale={fr}
            defaultMonth={startDate || minimumDate}
            numberOfMonths={numberOfMonths}
            disabled={[
              {
                dayOfWeek: [0, 6],
              },
              ...(disableMinDate ? [] : [{ before: minimumDate }]),
            ]}
            footer={
              startDate && endDate ? (
                <button
                  className="text-midnightblue hover:text-teal p-2 text-xs"
                  onClick={() => {
                    onRangeDateChange({
                      from: undefined,
                      to: undefined,
                    });
                  }}
                >
                  Sélectionner une nouvelle date
                </button>
              ) : null
            }
          />
        )}
       
      </>
    );
  };

  if (variant === "inline") {
    return (
      <div className="flex flex-col gap-1">
        {labelProps?.children ? (
          <Label
            className={cn(
              `text-base font-bold`,
              theme === "light" ? "text-midnightblue" : "text-teal",
            )}
            {...labelProps}
          />
        ) : null}

        <div className={`relative flex w-full flex-col gap-2`}>
          <CalendarComponent />

          {displayRangeLabel ? (
            <div className="text-midnightblue flex flex-wrap items-center gap-2 text-xs">
              {!startDate && <span>Sélectionnez une date</span>}
              {displayRangeLabel && startDate && endDate && (
                <span className="text-dark-iron">
                  ({getDateDifferenceInDays({ endDate, startDate })} jours)
                </span>
              )}
            </div>
          ) : null}
          <ErrorList errors={errors} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`relative flex w-full flex-col gap-2 ${
        showRecurring ? "lg:min-w-[150px]" : "lg:min-w-[240px]"
      }`}
      ref={dateWrapper}
    >
      {labelProps?.children ? (
        <Label
          as="label"
          aria-label="Choisissez une date de prestation"
          htmlFor={id}
          {...labelProps}
          className={cn(
            paragraphVariants({
              variant: "secondary",
              size: "md",
            }),
            "font-medium",
            "text-start",
            labelProps.className,
          )}
        >
          {labelProps.children}
        </Label>
      ) : null}

      <div className={`relative w-full`}>
        <Popover
          open={openDropdown}
          onOpenChange={(newOpenValue) => {
            setOpenDropDown(newOpenValue);
          }}
        >
          <PopoverTrigger asChild>
            <Button
              ref={buttonRef}
              type="button"
              variant={"datepicker"}
              size={"marketplace"}
              className={cn(
                "w-full justify-start text-left font-normal",
                paragraphVariants({
                  size: "md",
                  variant: "secondary",
                }),
                (showRecurring && !startDate) ||
                  (!showRecurring && !startDate && !endDate
                    ? "text-dark-iron"
                    : "text-midnightblue"),
              )}
            >
              <span>
                {startDate ? (
                  formatDate({ date: startDate, dateFmt: "dd/MM/yyyy" })
                ) : (
                  <span className="text-xs">{placeholder}</span>
                )}
              </span>

              {endDate ? (
                <>
                  <span> - </span>
                  <span>
                    {formatDate({ date: endDate, dateFmt: "dd/MM/yyyy" })}
                  </span>
                </>
              ) : null}
              <CalendarIcon
                className="text-midnightblue absolute inset-y-0 right-0 my-auto mr-2 size-5 shrink-0"
                aria-hidden="true"
              />
            </Button>
          </PopoverTrigger>

          <PopoverContent className="w-auto p-0">
            <CalendarComponent />
            {errorId ? (
              <ErrorList className="px-3 pb-2" id={errorId} errors={errors} />
            ) : null}
          </PopoverContent>
        </Popover>
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
};
